exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-article-category-js": () => import("./../../../src/templates/article-category.js" /* webpackChunkName: "component---src-templates-article-category-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-therapist-js": () => import("./../../../src/templates/therapist.js" /* webpackChunkName: "component---src-templates-therapist-js" */),
  "component---src-templates-therapists-js": () => import("./../../../src/templates/therapists.js" /* webpackChunkName: "component---src-templates-therapists-js" */),
  "component---src-templates-treatment-category-js": () => import("./../../../src/templates/treatment-category.js" /* webpackChunkName: "component---src-templates-treatment-category-js" */),
  "component---src-templates-treatment-js": () => import("./../../../src/templates/treatment.js" /* webpackChunkName: "component---src-templates-treatment-js" */),
  "component---src-templates-treatments-js": () => import("./../../../src/templates/treatments.js" /* webpackChunkName: "component---src-templates-treatments-js" */)
}

